import React, { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Topbar = ({ toggleSidebar }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check for mobile view

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      sx={{ width: "100%" }} // Full width for Topbar
    >
      {/* Left: Hamburger Menu for mobile view */}
      {isMobile && (
        <IconButton onClick={toggleSidebar}>
          <MenuOutlinedIcon />
        </IconButton>
      )}

      {/* Center: Flex grow box ensures the right icon is pushed to the edge */}
      <Box flexGrow={1} />

      {/* Right: Theme Toggle Button */}
      <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === "dark" ? (
          <LightModeOutlinedIcon />
        ) : (
          <DarkModeOutlinedIcon />
        )}
      </IconButton>
    </Box>
  );
};

export default Topbar;

import React, { useMemo, Suspense, useState, useCallback } from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Topbar from "./Global/Topbar";
import Sidebar from "./Global/Sidebar";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@emotion/react";


const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Wrap the toggle function in useCallback to ensure stable reference
  const handleSidebarToggle = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);
  console.log(isSidebarOpen);
  // Memoize Sidebar and Topbar to prevent unnecessary re-renders
  const memoizedTopbar = useMemo(
    () => <Topbar toggleSidebar={handleSidebarToggle} />,
    [handleSidebarToggle]
  );
  const memoizedSidebar = useMemo(
    () => (
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        handleSidebarToggle = {handleSidebarToggle}
        isMobile={isMobile}
      />
    ),
    [isSidebarOpen, handleSidebarToggle, isMobile]
  );

  const renderLazyImage = (avif, webp, fallback) => (
    <picture>
      <source srcSet={avif} type="image/avif" />
      <source srcSet={webp} type="image/webp" />
      <img
        src={fallback}
        alt="Phil Logo"
        style={{ height: "65px", width: "65px", marginRight: "8px", marginLeft:"10px"}}
      />
    </picture>
  );

  return (
    <Box
      sx={{
        paddingTop:"20px",
        display: "grid",
        gridTemplateRows: "auto 1fr", // Topbar only for private routes
        gridTemplateColumns: isMobile ? "1fr" : "auto 1fr", // Sidebar only for private routes
        height: "100vh",
        width: "100vw",
        gridTemplateAreas: isMobile
          ? `"topbar" "protected"`
          : `"logo topbar" "sidebar protected"`, // Layout for non-private routes
      }}
    >
      {/* Logo Area */}
      {!isMobile && (
        <Grid
          item
          gridArea="logo"
          px={1}
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "primary.main",
            color: "#fff",
            
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
            {renderLazyImage(
              require("../components/logo/PhilPicksIcon.avif"),
              require("../components/logo/PhilPicksIcon.webp"),
              "",
              "Phil Logo"
            )}

            <Typography
              variant="h2"
              sx={{
                color: "#00DF82",
                fontFamily: "'Nunito', sans-serif",
                fontWeight: "bold",
              }}
            >
              PhilPicks
            </Typography>
          </Box>
        </Grid>
      )}

      {/* Sidebar - Render only for private routes */}

      <Box
        gridArea="sidebar"
        sx={{
          overflow: "auto",
          width: "auto",
        }}
      >
        {memoizedSidebar}
      </Box>

      {/* Topbar - Render only for private routes */}
      <Box gridArea="topbar">{memoizedTopbar}</Box>

      {/* Main Content */}
      <Box
        gridArea={"protected"}
        sx={{
          overflow: "auto",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Suspense
          fallback={
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                paddingTop: "30px",
                backgroundColor:"#1E2846"
              }}
            >
              <CircularProgress />
            </Box>
          }
        >
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

export default Layout;

import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "./Layout"; // Shared layout for private routes
import PrivateRoute from "../routes/privateroutes"; // Authentication logic


// Lazy-loaded components for protected routes
const Dashboard = React.lazy(() => import("./UserPages/Dashboard"));
const Projections = React.lazy(() => import("./UserPages/Projections"));
const Calendar = React.lazy(() => import("./UserPages/AdvancedPicks/calendar"));
const SmartPicks = React.lazy(() => import("./UserPages/SmartPicks"));
const BOTS = React.lazy(() => import("./UserPages/Bots"));
const ModelPerformance = React.lazy(() =>
  import("./UserPages/ModelPerformance")
);
const BOTPerformance = React.lazy(() => import("./UserPages/BotPerformance"));
const Referral = React.lazy(() => import("./UserPages/Referral"));
const FAQ = React.lazy(() => import("./UserPages/Faq"));
const UserManagement = React.lazy(() =>
  import("./AdminPages/UserManagement/usermanagement")
);
const Invoices = React.lazy(() => import("./AdminPages/Invoices"));
const CreateUser = React.lazy(() => import("./AdminPages/CreateUser"));
const AdminDashboard = React.lazy(() => import("./AdminPages/AdminDashboard"));
const Cancel = React.lazy(() => import("./PaymentPages/cancel"));
const Success = React.lazy(() => import("./PaymentPages/success"));

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <Routes>
        {/* All protected routes use the Layout */}
        <Route path="/" element={<Layout  />}>
          {/* Admin-Specific Routes */}
          <Route
            path="usermanagement"
            element={<PrivateRoute component={UserManagement} requiresAdmin />}
          />
          <Route
            path="invoices"
            element={<PrivateRoute component={Invoices} requiresAdmin />}
          />
          <Route
            path="createuser"
            element={<PrivateRoute component={CreateUser} requiresAdmin />}
          />
          <Route
            path="admin/dashboard"
            element={<PrivateRoute component={AdminDashboard} requiresAdmin />}
          />

          {/* General Protected Routes */}
          <Route
            path="dashboard"
            element={<PrivateRoute component={Dashboard} />}
          />
          <Route
            path="prizepicks/projections"
            element={<PrivateRoute component={Projections} />}
          />
          <Route
            path="prizepicks/advanced"
            element={<PrivateRoute component={Calendar} />}
          />
          <Route
            path="prizepicks/smartpicks"
            element={<PrivateRoute component={SmartPicks} />}
          />
          <Route
            path="prizepicks/bots"
            element={<PrivateRoute component={BOTS} />}
          />
          <Route
            path="model/performance"
            element={<PrivateRoute component={ModelPerformance} />}
          />
          <Route
            path="bot/performance"
            element={<PrivateRoute component={BOTPerformance} />}
          />
          <Route
            path="referral"
            element={<PrivateRoute component={Referral} />}
          />
          <Route path="faq" element={<PrivateRoute component={FAQ} />} />
          <Route path="cancel" element={<PrivateRoute component={Cancel} />} />
          <Route
            path="success"
            element={<PrivateRoute component={Success} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useAuth } from "../context/AuthContext";

let countdownInterval;
let timeout;

const useInactivityLogout = (isAuthenticated) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { logout } = useAuth();

  const clearSessionTimeout = () => clearTimeout(timeout);
  const clearSessionInterval = () => clearInterval(countdownInterval);

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onIdle = () => {
    const delay = 1000;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 90; // 90 seconds countdown
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  useIdleTimer({
    onIdle,
    debounce: 250,
    timeout: 15 * 60 * 1000, // 15 minutes of idle time
  });

  useEffect(() => {
    return () => {
      clearSessionInterval();
      clearSessionTimeout();
    };
  }, []);

  return { timeoutModalOpen, timeoutCountdown, handleLogout, handleContinue };
};

export default useInactivityLogout;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // Your global CSS file
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/context/AuthContext";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Helmet } from "react-helmet";  // Import React Helmet for dynamically modifying <head>

const root = ReactDOM.createRoot(document.getElementById("root"));

function Main() {
  const [theme, colorMode] = useMode(); // Use the custom hook to manage theme

  // Path to the production CSS file
  const cssLink = "/static/css/main.f992a547.css"; // Update this dynamically in production

  return (
    <>
      {/* Use Helmet to inject the preload link dynamically */}
      <Helmet>
        <link
          rel="preload"
          href={cssLink} // Correct path to your production CSS file
          as="style"
          fetchpriority="high"
        />
      </Helmet>

      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline /> {/* MUI baseline styles */}
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

root.render(
  <Main />
);

import React, { useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import CircularProgress from "@mui/material/CircularProgress";
import { ColorModeContext, useMode } from "./theme";
import { useAuth } from "./components/context/AuthContext";
import useInactivityLogout from "./components/hooks/useInactivityLogout";
import AppRoutes from "./pages/AppRoutes";
import NotFound from "./pages/UserPages/NotFound";
import { privateRoutes } from "./routes/privateroutes";
// Lazy load all route components
const Home = React.lazy(() => import("./pages/UserPages/Home/Home"));
const SessionTimeoutDialog = React.lazy(() =>
  import("./components/hooks/SessionTimeoutDialog")
);

const Login = React.lazy(() => import("./pages/UserPages/Login"));
const SignupForm = React.lazy(() =>
  import("./pages/UserPages/SignupForm/SignupForm")
);
const Loading = React.lazy(() => import("./pages/UserPages/Loading"));
const Subscriptions = React.lazy(() =>
  import("./pages/PaymentPages/subscriptions")
);

function App() {
  const [theme, colorMode] = useMode();

  const { currentUser, loading } = useAuth();
  const location = useLocation();
  const isPrivateRoute = privateRoutes.includes(location.pathname);
  console.log(location)

  const { timeoutModalOpen, timeoutCountdown, handleLogout, handleContinue } =
    useInactivityLogout(!!currentUser);

  // Dynamically set the body background color based on theme mode
  useEffect(() => {
    document.body.style.backgroundColor =
      theme.palette.mode === "light" ? "#ffffff" : "#141B32";
  }, [theme.palette.mode]);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<CircularProgress />}>
          <Loading />
        </Suspense>
      </ThemeProvider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Suspense fallback={<CircularProgress />}>
            <Box className="content">
              <Grid
                sx={{
                  display: "grid",

                  height: "100vh", // Full viewport height
                  width: "100vw", // Full viewport width
                }}
              >
                {/* Main content */}
                <Grid
                  sx={{
                    overflow: "visible",
                  }}
                >
                  {/* Session Timeout Dialog */}
                  <SessionTimeoutDialog
                    countdown={timeoutCountdown}
                    open={timeoutModalOpen}
                    onLogout={handleLogout}
                    onContinue={handleContinue}
                  />
                  {/* Application Routes */}
                  <Routes>
                    {/* Non-Protected Routes */}
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignupForm />} />
                    <Route path="/subscriptions" element={<Subscriptions />} />

                    {isPrivateRoute ? (
                      <Route
                        path="/*"
                        element={<AppRoutes isPrivateRoute={isPrivateRoute} />}
                      />
                    ) : (
                      <Route path="*" element={<NotFound />} />
                    )}
                  </Routes>
                </Grid>
              </Grid>
            </Box>
          </Suspense>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

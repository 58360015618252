import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';  // You can choose any spinner
import { useTheme } from '@mui/material/styles'; // Use MUI's useTheme hook
import { tokens } from '../../theme'; // Import tokens for color mapping

const Loading = () => {
  const theme = useTheme(); // Get the current theme directly from ThemeProvider
  const colors = tokens(theme.palette.mode); // Get color tokens based on current theme mode

  return (
    <div style={styles.loadingContainer(colors, theme)}>
      <ClipLoader 
        color={theme.palette.mode === 'dark' ? colors.greenAccent[500] : colors.primary[700]} 
        loading={true} 
        size={50} 
      />
      <p style={{ color: theme.palette.mode === 'dark' ? colors.grey[100] : colors.primary[800], marginTop: '20px' }}>
        Loading...
      </p>
    </div>
  );
};

const styles = {
  loadingContainer: (colors, theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Center the loading screen vertically and horizontally
    width: '100vw', // Ensure it takes full width
    position: 'fixed', // Fixed position to overlay other content
    top: 0, // Align to the top of the viewport
    left: 0, // Align to the left of the viewport
    backgroundColor: theme.palette.mode === 'dark' ? colors.primary[400] : colors.primary[400], // Set the background color based on the theme
    zIndex: 1000, // Ensure it appears on top of other content
  }),
};

export default Loading;

import { initializeApp } from "firebase/app";

let authInstance, firestoreInstance, databaseInstance;

// Your Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase app (lightweight and safe to always initialize here)
const app = initializeApp(firebaseConfig);

// Lazy load the Auth service
export const getFirebaseAuth = async () => {
  if (!authInstance) {
    const { getAuth } = await import("firebase/auth");
    authInstance = getAuth(app);
  }
  return authInstance;
};

// Lazy load the Firestore service
export const getFirestoreDb = async () => {
  if (!firestoreInstance) {
    const { getFirestore } = await import("firebase/firestore");
    firestoreInstance = getFirestore(app);
  }
  return firestoreInstance;
};

// Lazy load the Realtime Database service
export const getRealtimeDb = async () => {
  if (!databaseInstance) {
    const { getDatabase } = await import("firebase/database");
    databaseInstance = getDatabase(app);
  }
  return databaseInstance;
};

// Export the Firebase app instance
export default app;

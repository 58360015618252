import React from 'react';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const location = useLocation(); // Get the current location (URL)

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Page Not Found</h1>
      <p>
        The page you're looking for "<strong>{location.pathname}</strong>" does not exist.
      </p>
    </div>
  );
};

export default NotFound;
